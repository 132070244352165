var riot = require('riot');
module.exports = 
riot.tag2('signin-modal', '<yield></yield> <modal name="signinmodal" onclose="{close}" title="Sign In" savetext="Sign In" onsave="{handlesignin}" esckey="{true}"> <signin onsignin="{parent.signedin}" hidebutton="{true}" hidesignup="{true}" hideforgotpassword="{parent.opts.hideforgotpassword}" forgot-password-custom-message="{parent.opts.forgotPasswordCustomMessage}" redirect="{parent.opts.onLoginRedirect}"></signin> </modal> <modal name="signoutmodal" onclose="{close}" title="Account" savetext="Logout" onsave="{logout}" esckey="{true}"> <ul class="accountlist"> <li><a href="/account/personal_details/">Personal Details</a></li> <li><a href="/account/change_password/">Change Password</a></li> </ul> </modal>', 'signin-modal { cursor: pointer; } .accountlist { list-style-type: none; padding: 0; }', 'onclick="{show}"', function(opts) {
var loginApi, logoutApi, resource;

resource = require('riot-kit/utils/apiResource.coffee');

loginApi = new resource(opts.src || '/duocms/api/login', true);

logoutApi = new resource(opts.src || '/duocms/api/logout', true);

this.on('mount', function() {
  this.root.trigger = this.trigger;
  this.signinmodal = this.tags.signinmodal;
  this.signoutmodal = this.tags.signoutmodal;
  this.signin = this.signinmodal.tags.signin;
  return this.update();
});

this.on('show', (function(_this) {
  return function() {
    return _this.show();
  };
})(this));

this.show = (function(_this) {
  return function() {
    document.addEventListener('keydown', _this.keydown);
    return loginApi.get(function(data, err) {
      if (err == null) {
        return _this.signoutmodal.show();
      } else {
        return _this.signinmodal.show();
      }
    });
  };
})(this);

this.keydown = (function(_this) {
  return function(e) {
    if (e.which === 13) {
      return _this.handlesignin();
    }
  };
})(this);

this.logout = (function(_this) {
  return function() {
    console.log('logout');
    return logoutApi.get(function(data, err) {
      if (err != null) {
        console.log('error', err);
      }
      if (err == null) {
        console.log('logged out', data);
      }
      if (opts.onLogoutRedirect != null) {
        window.location.href = opts.onLogoutRedirect;
      }
      return _this.signoutmodal.close();
    });
  };
})(this);

this.close = (function(_this) {
  return function() {
    document.removeEventListener('keydown', _this.keydown);
    return _this.signin.trigger('reset');
  };
})(this);

this.signedin = (function(_this) {
  return function(user) {
    _this.signinmodal.close();
    if ((opts.onsignin != null) && typeof opts.onsignin === "function") {
      return opts.onsignin(user);
    }
  };
})(this);

this.handlesignin = (function(_this) {
  return function() {
    return _this.signin.trigger('signin');
  };
})(this);
});