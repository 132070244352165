var riot = require('riot');
module.exports = 
riot.tag2('personal-details', '<div class="profile"> <formhorizontal width1="2" width2="10"> <div class="row"> <div class="col-sm-12"> <forminput name="first_name" value="{parent.user.first_name}" onupdate="{parent.handleUpdate}" label="First Name" isrequired></forminput> <forminput name="surname" value="{parent.user.surname}" onupdate="{parent.handleUpdate}" label="Surname" isrequired></forminput> <forminput name="email" value="{parent.user.email}" onupdate="{parent.handleUpdate}" label="Email" readonly="true"></forminput> <formtextarea name="address" value="{parent.user.address}" onupdate="{parent.handleUpdate}" label="Address"></formtextarea> <forminput name="postcode" value="{parent.user.postcode}" onupdate="{parent.handleUpdate}" label="Postcode"></forminput> <formselect name="country" value="{parent.user.country}" onupdate="{parent.handleUpdate}" label="Country" options="{parent.countryCodes}" default="Select Country"></formselect> <forminput name="phone1" value="{parent.user.phone1}" onupdate="{parent.handleUpdate}" label="Telephone" isrequired></forminput> <forminput name="phone2" value="{parent.user.phone2}" onupdate="{parent.handleUpdate}" label="Mobile"></forminput> <div onclick="{parent.submit}" class="btn btn-primary"> <i18n>Update Details</i18n> </div> </div> </div> </formhorizontal> </div>', '.profile .logout-btn { margin-bottom: -115px; } .profile .avatar { border: 1px solid #ccc; padding: 10px; margin-bottom: 20px; } .profile .avatar img { width: 100%; } .profile .row { margin-bottom: 10px; }', '', function(opts) {
var resource, userApi;

resource = require('riot-kit/utils/apiResource.coffee');

userApi = new resource('/duocms/api/users', true);

this.countryCodes = [
  {
    label: 'United Kingdom',
    value: 'UK'
  }
];

this.on('mount', function() {
  return userApi.get({
    id: 'me'
  }, (function(_this) {
    return function(user) {
      _this.user = user;
      return _this.update();
    };
  })(this));
});

this.handleUpdate = (function(_this) {
  return function(update) {
    _this.user[update.name] = update.value;
    return _this.update();
  };
})(this);

this.submit = (function(_this) {
  return function() {
    _this.user.id = 'me';
    return userApi.save(_this.user, function(data) {});
  };
})(this);
});