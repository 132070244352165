var riot = require('riot');
module.exports = 
riot.tag2('date_ranges', '<ul> <li each="{range in ranges}"><a href="{\'/blog/?month=\' + range.month}">{range.label}</a></li> </ul>', '', '', function(opts) {
var endAt, months, startAt;

this.ranges = [];

startAt = new Date(2015, 11);

endAt = new Date();

months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

this.on('mount', function() {
  var current;
  current = startAt;
  while (current.getTime() < endAt.getTime()) {
    this.addMonth(current);
    current.setMonth(current.getMonth() + 1);
  }
  return this.update();
});

this.addMonth = (function(_this) {
  return function(current) {
    var month;
    month = current.getMonth() + 1;
    if (month < 10) {
      month = "0" + month;
    }
    return _this.ranges.push({
      month: current.getFullYear() + "-" + month,
      label: months[current.getMonth()] + " " + current.getFullYear()
    });
  };
})(this);
});