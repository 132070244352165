var riot = require('riot');
module.exports = 
riot.tag2('scrolltop', '<span onclick="{scrollIt}">TOP</span>', '', '', function(opts) {
this.scrollIt = (function(_this) {
  return function(e) {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollBy(0, -8);
      return _this.timeOut = setTimeout(_this.scrollIt, 2);
    } else {
      return clearTimeout(_this.timeout);
    }
  };
})(this);
});