var riot = require('riot');
module.exports = 
riot.tag2('recaptcha-script', '', '', '', function(opts) {
var recaptchascript;

if (window.recaptchascript != null) {
  return;
}

recaptchascript = riot.observable();

recaptchascript.one('loadscript', (function(_this) {
  return function() {
    var script;
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js?onload=recapReady';
    return document.body.appendChild(script);
  };
})(this));

window.recaptchascript = recaptchascript;

window.recapReady = function() {
  return recaptchascript.trigger('loaded');
};
});
riot.tag2('recaptcha', '<recaptcha-script></recaptcha-script> <div id="{opts.name}"></div>', '', '', function(opts) {
this.on('mount', function() {
  this.recaptcha = window.recaptchascript;
  if (window.grecaptcha != null) {
    return this.load();
  } else if (this.recaptcha) {
    this.recaptcha.one('loaded', (function(_this) {
      return function() {
        return _this.load();
      };
    })(this));
    return this.recaptcha.trigger('loadscript');
  } else {
    throw new Error('ReCaptcha hasn\'t been loaded');
  }
});

this.callback = function(data) {
  if (opts.onupdate) {
    opts.onupdate(data);
  }
  if (opts.ontoken != null) {
    return opts.ontoken();
  }
};

this.load = (function(_this) {
  return function() {
    _this.recaptcha_id = window.grecaptcha.render(opts.name, {
      sitekey: opts.key,
      theme: "light",
      type: "image",
      callback: _this.callback,
      'expired-callback': _this.reset,
      size: opts.invisible ? 'invisible' : null
    });
    return _this.update();
  };
})(this);

this.execute = (function(_this) {
  return function() {
    return setTimeout(function() {
      return window.grecaptcha.execute(_this.recaptcha_id);
    }, 300);
  };
})(this);

this.reset = (function(_this) {
  return function() {
    return window.grecaptcha.reset(_this.recaptcha_id);
  };
})(this);

this.on('execute', (function(_this) {
  return function() {
    return _this.execute();
  };
})(this));
});