var riot = require('riot');
module.exports = 
riot.tag2('site-search', '<form action="/website_search/" method="GET"> <div class="search {closed:!open,open:open}"> <div class="form-group"> <div class="input-group"> <input placeholder="Search Website" name="q" class="form-control"> <div class="input-group-btn"> <button type="button" onclick="{toggle}" class="btn btn-link"><i class="fa fa-search"></i></button> </div> </div> </div> </div> </form>', '', '', function(opts) {
this.open = false;

this.toggle = (function(_this) {
  return function() {
    _this.open = !_this.open;
    return _this.q.focus();
  };
})(this);
});