require('./raw');require('./i18n');
riot.tag2('formhorizontal', '<form onsubmit="{opts.submit}" class="form-horizontal"><yield></yield></form>', '', '', function(opts) {
});
riot.tag2('formgroup', '<div if="{!opts.checkbox}" class="form-group {has-error:!valid}"> <label for="{parent.opts.id}" class="control-label col-sm-{opts.width1 || parent.opts.width1 || parent.parent.opts.width1 || \'4\'}"> <i18n content="{parent.opts.label || \'\'}"></i18n> </label> <div class="col-sm-{opts.width2 || parent.opts.width2 || parent.parent.opts.width2 || \'8\'}"><span if="{!parent.opts.addonbefore && !parent.opts.addonafter && !parent.opts.buttonbefore && !parent.opts.buttonafter}"><yield></yield></span> <div if="{parent.opts.addonbefore || parent.opts.addonafter}" class="input-group"><span if="{parent.opts.addonbefore}" riot-tag="raw" content="{parent.opts.addonbefore}" class="input-group-addon"></span> <i18n> <yield></yield> </i18n><span if="{parent.opts.addonafter}" riot-tag="raw" content="{parent.opts.addonafter}" class="input-group-addon"></span> </div> <div if="{parent.opts.buttonbefore || parent.opts.buttonafter}" class="input-group"><span if="{parent.opts.buttonbefore}" class="input-group-btn"> <button riot-tag="i18n" content="{parent.opts.buttonbefore}" onclick="{buttonbeforeclick}"> </button></span><yield></yield><span if="{parent.opts.buttonafter}" class="input-group-btn"> <button riot-tag="i18n" content="{parent.opts.buttonafter}" onclick="{buttonafterclick}" class="btn btn-default"></button></span></div><span if="{!valid}" riot-tag="i18n" content="{errorMessage}" class="help-block"></span> </div> </div> <div if="{opts.checkbox}" class="form-group {has-error:!valid}"> <div class="col-sm-offset-{opts.width1 || parent.opts.width1 || parent.parent.opts.width1 || \'4\'} col-sm-{opts.width2 || parent.opts.width2 || parent.parent.opts.width2 || \'8\'}"> <div class="checkbox"> <label><yield></yield></label> </div><span if="{!valid}" riot-tag="i18n" content="{errorMessage}" class="help-block"></span> </div> </div>', '', '', function(opts) {
var ref, ref1, ref2, ref3;

this.valid = true;

this.validate = false;

this.buttonbeforeclick = ((ref = this.parent) != null ? (ref1 = ref.opts) != null ? ref1.buttonbeforeclick : void 0 : void 0) ? this.parent.opts.buttonbeforeclick : function() {};

this.buttonafterclick = ((ref2 = this.parent) != null ? (ref3 = ref2.opts) != null ? ref3.buttonafterclick : void 0 : void 0) ? this.parent.opts.buttonafterclick : function() {};

this.on('mount', function() {
  var ref4, ref5, ref6;
  this.valid = true;
  this.validate = false;
  this.type = opts.checkbox ? 'checkbox' : ((ref4 = this.parent) != null ? (ref5 = ref4.opts) != null ? ref5.type : void 0 : void 0) || 'text';
  this.formsubmitbtn = this.findFormSubmit(this.root);
  if ((ref6 = this.formsubmitbtn) != null) {
    ref6.addEventListener('click', this.onsubmit);
  }
  return this.update();
});

this.on('update', function() {
  var ref10, ref11, ref12, ref13, ref4, ref5, ref6, ref7, ref8, ref9;
  this.required = (((ref4 = this.parent) != null ? (ref5 = ref4.opts) != null ? ref5.isrequired : void 0 : void 0) != null) && ((ref6 = this.parent) != null ? (ref7 = ref6.opts) != null ? ref7.isrequired : void 0 : void 0) !== false ? true : false;
  this.errorMessage = ((ref8 = this.parent) != null ? (ref9 = ref8.opts) != null ? ref9.errormessage : void 0 : void 0) != null ? (ref10 = this.parent) != null ? (ref11 = ref10.opts) != null ? ref11.errormessage : void 0 : void 0 : 'This field is required';
  this.isValid((ref12 = this.root.querySelector('input, select, textarea')) != null ? ref12.validity : void 0);
  if ((this.cursor != null) && ((ref13 = this.type) === 'text')) {
    if (this.root.querySelector('input, textarea')) {
      return this.root.querySelector('input, textarea').setSelectionRange(this.cursor, this.cursor);
    }
  }
});

this.on('unmount', function() {
  var ref4;
  return (ref4 = this.formsubmitbtn) != null ? ref4.removeEventListener('click', this.onsubmit) : void 0;
});

this.onsubmit = (function(_this) {
  return function(e) {
    var ref4;
    _this.validate = true;
    _this.isValid((ref4 = _this.root.querySelector('input, select, textarea')) != null ? ref4.validity : void 0);
    if (!_this.valid) {
      return e.preventDefault();
    }
  };
})(this);

this.findFormSubmit = function(node) {
  if (node == null) {
    return;
  }
  if (node.tagName === "FORM") {
    return node.querySelector('[type=submit]');
  }
  return this.findFormSubmit(node.parentNode);
};

this.handleChange = function(e) {
  var name, ref4, ref5, valid, value;
  if ((ref4 = this.type) === 'text') {
    this.cursor = e != null ? (ref5 = e.target) != null ? ref5.selectionStart : void 0 : void 0;
  }
  valid = this.isValid(e.target.validity);
  if (opts.onupdate == null) {
    return;
  }
  name = (e.target.id != null) && e.target.id.length ? e.target.id : e.target.name;
  value = opts.checkbox ? e.target.checked : e.target.value;
  if (opts.onupdate != null) {
    opts.onupdate({
      name: name,
      value: value,
      valid: this.valid
    }, e.target);
  }
  e.preventUpdate = true;
  return true;
};

this.startValidation = (function(_this) {
  return function(e) {
    _this.validate = true;
    _this.cursor = null;
    return _this.isValid(e.target.validity);
  };
})(this);

this.isValid = (function(_this) {
  return function(validity) {
    if (_this.validate && (validity != null)) {
      _this.valid = !validity.badInput && !validity.customError && !validity.patternMismatch && !validity.rangeOverflow && !validity.rangeUnderflow && !validity.stepMismatch && !validity.tooLong && !validity.tooShort && !validity.typeMismatch && validity.valid && !validity.valueMissing;
    }
    return _this.update();
  };
})(this);
});
riot.tag2('forminput', '<formgroup onupdate="{opts.onupdate}"> <input id="{parent.opts.id}" name="{parent.opts.name}" placeholder="{parent.opts.placeholder}" type="{type}" oninput="{handleChange}" value="{parent.opts.value}" __required="{required}" pattern="{parent.opts.pattern || \'.*\'}" __readonly="{parent.opts.readonly}" onblur="{startValidation}" class="form-control"> </formgroup>', '', '', function(opts) {
});
riot.tag2('formtextarea', '<formgroup onupdate="{opts.onupdate}"> <textarea id="{parent.opts.id}" name="{parent.opts.name}" rows="{parent.opts.rows || \'3\'}" oninput="{handleChange}" __required="{required}" placeholder="{parent.opts.placeholder}" __readonly="{parent.opts.readonly}" value="{parent.opts.value}" onblur="{startValidation}" class="form-control"></textarea> </formgroup>', '', '', function(opts) {
});
riot.tag2('formselect', '<formgroup onupdate="{opts.onupdate}"> <select id="{parent.opts.id}" name="{parent.opts.name}" onchange="{handleChange}" value="{parent.opts.value}" __required="{required}" __readonly="{parent.opts.readonly}" onblur="{startValidation}" class="form-control"> <option if="{parent.opts.default}" value="" riot-tag="i18n" content="{parent.opts.default}"></option> <option each="{parent.opts.options}" no-reorder value="{value}" __selected="{value==parent.parent.opts.value}">{label}</option> </select> </formgroup>', '', '', function(opts) {
});
riot.tag2('formcheckbox', '<formgroup onupdate="{opts.onupdate}" checkbox="{true}"> <input type="checkbox" id="{parent.opts.id}" name="{parent.opts.name}" onclick="{handleChange}" onblur="{startValidation}" __checked="{parent.opts.value}" __readonly="{parent.opts.readonly}" __required="{required}"><span riot-tag="i18n" content="{parent.opts.label}"></span> </formgroup>', '', '', function(opts) {
});
riot.tag2('formstatic', '<formgroup onupdate="{opts.onupdate}"> <p class="form-control-static">{parent.opts.value}</p> </formgroup>', '', '', function(opts) {
});
riot.tag2('formstatictextarea', '<formgroup onupdate="{opts.onupdate}"> <p riot-tag="raw" content="{parent.opts.value}" class="form-control-static"></p> </formgroup>', '', '', function(opts) {
this.on('update', function() {
  var ref;
  return this.opts.value = (ref = this.opts.value) != null ? ref.split('\n').join('<br>') : void 0;
});
});
riot.tag2('formsubmit', '<formgroup onupdate="{opts.onupdate}"> <button id="{parent.opts.id}" name="{parent.opts.name}" type="{type}" onclick="{parent.opts.onclick}" value="{parent.opts.value}" class="btn btn-default form-control"> <i18n><yield></yield></i18n> </button> </formgroup>', '', '', function(opts) {
});
riot.tag2('formtypeahead', '<formgroup onupdate="{handleChange}"> <input id="{parent.opts.id}" name="{parent.opts.name}" placeholder="{parent.opts.placeholder}" type="{type}" value="{parent.opts.value}" onkeydown="{parent.handleType}" oninput="{parent.handleChange}" __required="{required}" pattern="{parent.opts.pattern || \'.*\'}" __readonly="{parent.opts.readonly}" onblur="{startValidation &amp;&amp; parent.handleHide}" class="form-control"> <ul if="{parent.show}" role="menu" riot-style="{parent.dropstyle}" class="nav nav-pills nav-stacked drop"> <li each="{v,k in parent.matches}" class="{active:k==parent.parent.selected}"><a onclick="{parent.parent.setFromClick}">{v[parent.parent.viewfield]}</a></li> </ul> </formgroup>', 'formtypeahead .drop{ background:white; position:absolute; list-style:none; border-radius:5; z-index:2; box-shadow:0 3px 5px rgba(0,0,0,0.5); }', '', function(opts) {
var keycodes;

keycodes = {
  down: 40,
  up: 38,
  enter: 13,
  tab: 9
};

this.on('mount', function() {
  this.matches = [];
  this.dropstyle = "";
  this.show = false;
  this.selected = 0;
  return this.viewfield = opts.viewfield || opts.field;
});

this.handleType = (function(_this) {
  return function(e) {
    if (e.which === keycodes.enter || e.which === keycodes.tab) {
      e.preventDefault();
      _this.setVal();
    }
    if (e.which === keycodes.down) {
      _this.selected++;
    }
    if (e.which === keycodes.up) {
      _this.selected--;
    }
    if (_this.selected > _this.matches.length - 1) {
      _this.selected = 0;
    }
    if (_this.selected < 0) {
      _this.selected = _this.matches.length - 1;
    }
    if (!_this.show) {
      _this.show = true;
      _this.fixWidth();
      _this.selected = 0;
    }
    setTimeout(function() {
      _this.filterOptions();
      return _this.update();
    }, 100);
    return e.preventUpdate = true;
  };
})(this);

this.filterOptions = (function(_this) {
  return function() {
    var op, ref, regx, val;
    val = _this.root.querySelector('input').value;
    if (!((ref = opts.options) != null ? ref.length : void 0)) {
      return;
    }
    regx = new RegExp("^" + val + ".*", 'i');
    _this.matches = ((function() {
      var i, len, ref1, results;
      ref1 = opts.options;
      results = [];
      for (i = 0, len = ref1.length; i < len; i++) {
        op = ref1[i];
        if (op[opts.field].match(regx)) {
          results.push(op);
        }
      }
      return results;
    })()) || [];
    if (_this.matches.length > opts.max) {
      return _this.matches.length = _this.opts.max;
    }
  };
})(this);

this.setFromClick = (function(_this) {
  return function(e) {
    e.preventDefault();
    _this.selected = e.item.k;
    return _this.setVal();
  };
})(this);

this.handleChange = (function(_this) {
  return function(e) {
    var name, value;
    if (opts.onupdate == null) {
      return true;
    }
    name = (e.target.id != null) && e.target.id.length ? e.target.id : e.target.name;
    value = opts.checkbox ? e.target.checked : e.target.value;
    opts.onupdate({
      name: name,
      value: value,
      data: _this.matches[_this.selected]
    }, e.target, e);
    return true;
  };
})(this);

this.setVal = (function(_this) {
  return function() {
    if (_this.root.querySelector('input') != null) {
      _this.root.querySelector('input').value = _this.matches[_this.selected][opts.field];
    }
    _this.root.querySelector('input').dispatchEvent(new Event('input'));
    _this.update();
    return _this.handleHide();
  };
})(this);

this.handleHide = (function(_this) {
  return function() {
    _this.opacity = "opacity:0;";
    _this.fixWidth();
    _this.update();
    return setTimeout(function() {
      _this.show = false;
      _this.opacity = "opacity:1;";
      return _this.update();
    }, 1000);
  };
})(this);

this.fixWidth = (function(_this) {
  return function() {
    var inputPos;
    inputPos = _this.root.querySelector('input').getBoundingClientRect();
    _this.dropstyle = "width:" + inputPos.width + "px;" + _this.opacity;
    return _this.update();
  };
})(this);
});