
riot.tag2('popover', '<div if="{show}" onclick="{popclicked}" name="pop" riot-style="display:block;opacity:{opacity};top:{top};left:{left}" class="popover fade {placement} {in:show}"> <div class="arrow"></div> <h3 id="popover-top" if="{opts.title}" class="popover-title">{opts.title}</h3> <div style="padding:9px" class="popover-content"><yield></yield></div> </div>', '', '', function(opts) {
var placementlib;

placementlib = require('../utils/placement.coffee');

this.show = false;

this.timeOut = null;

this.opacity = 0;

this.placement = this.opts.placement || "bottom";

this.parentNode = this.root.parentNode;

this.hide = (function(_this) {
  return function() {
    document.removeEventListener('click', _this.hide, false);
    _this.parentNode.appendChild(_this.root);
    _this.show = false;
    if (opts.hiding) {
      opts.hiding();
    }
    return _this.update();
  };
})(this);

this.popclicked = function(e) {
  e.preventDefault();
  return e.stopPropagation();
};

this.on('unmount', (function(_this) {
  return function() {
    if (_this.show) {
      _this.hide();
    }
    if (_this.timeOut) {
      return clearTimeout(_this.timeOut);
    }
  };
})(this));

this.clicked = (function(_this) {
  return function(e) {
    if (!_this.show) {
      _this.show = true;
      _this.update();
      _this.parentNode = _this.root.parentNode;
      document.body.appendChild(_this.root);
      return _this.timeOut = setTimeout(function() {
        var pos;
        document.addEventListener('click', _this.hide, false);
        pos = placementlib({
          placement: _this.placement,
          target: e.target,
          element: _this.pop
        });
        _this.placement = pos.placement;
        _this.top = Math.round(pos.top) + "px";
        _this.left = Math.round(pos.left) + "px";
        _this.opacity = 1;
        return _this.update();
      });
    } else {
      return _this.timeOut = setTimeout(function() {
        return _this.hide();
      });
    }
  };
})(this);
});